@import 'src/colors.scss';

.color_selector {
	display: flex;
	gap: 1em;
	align-items: center;
	position: relative;

	.color_popover {
		position: absolute;
		z-index: 10;
		top: 13px;
		left: 150px;
		border-radius: 8px;
		box-shadow: 0 0 5px #7b7a7a;
	}

	:global .ant-avatar {
		&:hover {
			cursor: pointer;
			filter: brightness(90%) !important;
		}
	}
}

