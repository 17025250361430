@import 'src/colors';

.archive {
  text-align: start;
  height: 100%;


  .title {
    margin-bottom: 20px;
    display: flex;
    gap: 1em;

    h4 {
      font-size: 18px;
      margin-bottom: 6px;
    }
    .year_select {

      width: 220px;

      :global(.ant-select-selection-item) {
        color: $pinkRed;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .filter {
    text-align: center;
    margin-bottom: 14px;
  }
}
