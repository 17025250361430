@import 'src/colors';

.tangerine_modal:global(.ant-modal.ant-modal-confirm) {
  :global(.ant-modal-close) {
    top: 5px;
    right: 5px;
  }

  :global(.ant-modal-confirm-btns) {
    display: none;
  }

  :global(.ant-modal-content) {
    padding: 12px 16px;

    :global(.ant-modal-body) {
      :global(.ant-modal-confirm-content) {
        width: 100%;

        .tangerine_form {
          :global( > span.ant-typography) {
            margin-top: 15px;
            display: block;
            margin-bottom: 2px;
          }

          .recipients {
            display: flex;
            margin-bottom: 30px;
            gap: 8px;

            .otr_checkbox {
              width: 100px;
            }

            > div:not(.user_select_container) {
              padding-top: 3px;
            }

            //.user_select_container {
            //	padding-left: 12px;
            //	flex: 1 0 auto;
            //	max-width: calc(100% - 165px);
            //
            //	.max_recipients_hint {
            //		font-style: italic;
            //		color: gray;
            //
            //		&.is_max {
            //			color: red;
            //		}
            //	}
            //
            //	.private_checkbox {
            //
            //	}
            //}


          }

          //
          //:global textarea.ant-input {
          //	border-color: rgb(119, 177, 164);
          //	box-shadow: 0 0 0 2px rgba(172, 212, 203, 0.3);
          //}
          //
          //
          //.message_invalid {
          //	//border-color: $pinkRed;
          //	:global textarea.ant-input {
          //		border-color: rgba(249, 131, 131, 0.96);
          //		box-shadow: 0 0 0 2px rgb(230 78 78 / 10%);;
          //	}
          //}
          //
          //:global textarea.ant-input {
          //	line-height: 18px;
          //
          //}
          //
          //.hint {
          //	color: gray;
          //	font-style: italic;
          //
          //	&.hint_invalid {
          //		color: rgba(222, 65, 65, 0.93);
          //	}
          //}

          .button_container {
            text-align: end;

            .magic_btn {
              .magic_icon {
                font-size: 16px;
              }
              &:not(:disabled) {
                &:not(:hover),
                &:not(:focus),
                &:not(:active) {
                  .magic_icon {
                    color: rgb(228, 170, 79);
                  }
                }



                &:focus,
                &:hover,
                &:active {
                  .magic_icon {
                    color: #9ead6e;
                  }
                }
              }


            }
          }

          &.private {
            .private_checkbox {
              font-weight: 600;
            }
          }

          &.is_reply {
            .recipients {
              .user_select_container {
                max-width: 100%;
              }
            }
          }
        }
      }

    }
  }
}
