@import 'src/colors.scss';

$error-color: rgb(202, 14, 14);
$error-red: rgba(174, 32, 32, 0.8);
$error-red-darker: rgba(210, 10, 10, 0.7);
$error-red-dark: rgba(224, 51, 51, 0.25);


$globe-width: 16px;
$globe-height: 22px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.3;
$fasterAnimationMultiplier: 0.35;
$animationMultiplier: 1.4;


@mixin boxShadow($color) {
	box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread $color;
}

.login_container {
	display: flex;
	width: 100%;
	position: relative;
	align-items: center;
	justify-content: center;
	height: 100%;

	.card {
		width: 400px;
		position: relative;
		//background: whiten($backgroundColor, 60%);
		background: linear-gradient(90deg, $backgroundPink 0%, $backgroundBlue 35%, $backgroundYellow 100%);

		:global(.ant-card-head) {
			padding: 0;
		}


		.hint {
			margin-bottom: 20px;
			color: gray;
			text-align: center;
			font-size: 13px;
			//padding-left: 30px;
		}

		:global(.ant-input-affix-wrapper) {

		}

		.ok_button {
			margin-top: 20px;
		}

		:global(.ant-card-head-title) {
			text-align: center;
			//padding: 12px 0;
		}

		:global(.ant-input-affix-wrapper.invalid) {
			border-color: $error-red-darker;

			&:focus,
			&:active,
			&:hover {
				//border-color: $error-red-darker;
				box-shadow: 0 0 0 2px $error-red-dark;
			}
		}

		:global(.ant-card-body) {
			padding: 0;

			.content {
				padding: 12px 24px;
			}

			.button_container {
				text-align: center;
			}

			:global(.ant-divider-horizontal) {
				margin-top: 12px;
				margin-bottom: 6px;
			}
		}

		.footer {
			padding: 10px 14px;
			padding-bottom: 16px;
			font-size: 14px;
		}
	}
}


:global(.lights) {
	width: 100%;
	position: relative;
	height: 50px;


	//body {
	//	background: #000;
	//}
	:global(.lightrope) {
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		position: absolute;
		z-index: 1;
		margin: -15px 0 0 0;
		padding: 0;
		pointer-events: none;
		width: 100%;

		li {
			position: relative;
			animation-fill-mode: both;
			animation-iteration-count: infinite;
			list-style: none;
			margin: 0;
			padding: 0;
			width: $globe-width;
			height: $globe-height;
			border-radius: 50%;
			margin: $globe-spacing/2;
			display: inline-block;
			background: rgba(115, 192, 135, 1);
			box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba(115, 192, 135, 1);
			animation-name: flash-1;
			animation-duration: $animationMultiplier * 2s;
			//&:global(.color-1) {
			//	.lightBulb(rgb(0,247,165), 2s, flash-1);
			//}
			//
			//&:global(.color-2) {
			//	.lightBulb(rgb(0,255,255), 0.4s, flash-2);
			//}
			//
			//
			//&:global(.color-2) {
			//	.lightBulb(rgb(247,0,148), 1.1s, flash-3);
			//}

			//
			&:nth-child(2n+1) {
				@include boxShadow($yellow);

				background: $yellow;
				animation-name: flash-2;
				animation-duration: $animationMultiplier * 0.4s;
			}

			&:nth-child(4n+2) {
				@include boxShadow($pink);
				background: $pink;
				animation-name: flash-3;
				animation-duration: $animationMultiplier * 1.1s;
			}

			&:nth-child(odd) {
				animation-duration: $animationMultiplier * 1.8s;
			}

			&:nth-child(3n+1) {
				animation-duration: $animationMultiplier * 1.4s;
			}

			&:before {
				content: "";
				position: absolute;
				background: #222;
				width: ($globe-width - 2);
				height: $globe-height/3;
				border-radius: 3px;
				top: (0 - ($globe-height/6));
				left: 1px;
			}

			&:after {
				content: "";
				top: (0 - $globe-height/2);
				left: $globe-width - 3;
				position: absolute;
				width: $globe-spacing + 12;
				height: ($globe-height/3 * 2);
				border-bottom: solid #222 2px;
				border-radius: 50%;
			}

			&:last-child:after {
				content: none;
			}

			&:first-child {
				margin-left: -$globe-spacing;
			}
		}
	}

	&:global(.playing) {
		:global(li) {
			//animation-play-state: running;
			animation-duration: $fasterAnimationMultiplier *  2s;

			&:nth-child(2n+1) {
				animation-duration: $fasterAnimationMultiplier *  0.4s;
			}

			&:nth-child(4n+2) {
				animation-duration: $fasterAnimationMultiplier *  1.1s;
			}

			&:nth-child(odd) {
				animation-duration: $fasterAnimationMultiplier * 1.8s;
			}

			&:nth-child(3n+1) {
				animation-duration: $fasterAnimationMultiplier * 1.4s;
			}

		}
	}

	@keyframes flash-1 {
		0%, 100% {
			@include boxShadow($yellow);
			background: $yellow;
		}
		50% {
			@include boxShadow(rgba($yellow, $light-off-opacity));
			background: rgba($yellow, $light-off-opacity);
		}
		//box-shadow: 0 $globe-height/6 $globe-width*2 $globe-spread rgba($yellow,0.2);}
	}
	@keyframes flash-2 {
		0%, 100% {
			@include boxShadow($green);
			background: $green;
		}
		50% {
			@include boxShadow(rgba($green, $light-off-opacity));
			background: rgba($green, $light-off-opacity);
		}
	}
	@keyframes flash-3 {
		0%, 100% {
			@include boxShadow($pink);
			background: $pink;
		}
		50% {
			@include boxShadow(rgba($pink, $light-off-opacity));
			background: rgba($pink, $light-off-opacity);
		}
	}
}
