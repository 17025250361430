@import 'src/colors';

.list_item_header {
	position: relative;
	line-height: 16px;
	font-weight: normal;
	//font-style: italic;
	display: flex;
	gap: 10px;

	.avatar_icon {
		position: absolute;
		top: -3px;
		font-size: 16px;
		left: -68px;

		:global(.anticon.anticon-lock) {
			color: $brownedGray;
		}

		.icon_otr {
			path {
				stroke-width: 20;
				stroke: white;
			}
		}
	}

	.create_date {
		position: absolute;
		top: 32px;
		left: -64px;
		font-style: italic;
		font-size: 11px;
		color: rgba(10, 10, 10, 0.5);

		&.date_today {
			left: -52px;
		}
	}

	.sender {
		font-weight: 500;
		flex-shrink: 0;
		color: rgba(0, 0, 0, 0.85)
	}

	.arrow_icon {
		font-size: 12px;
		color: gray
	}

	.recipients_container {
		font-weight: 500;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: rgba(0, 0, 0, 0.85);

		.receiver_all {
			color: rgb(205, 97, 51);
			font-style: italic;
		}
	}

	&.sent_to_me {
		.sender,
		.recipients_container {
			font-weight: 600;
		}
	}
}

