@import 'src/colors';

$allColor: $purplePlum;
$outboxColor: rgb(242, 72, 15);
$inboxColor: $greenBright;
$deptColor: $blueLight;
$likedColor: rgb(245, 23, 3);

@mixin tagStyle($tagColor, $textColor: $tagColor) {
  border-color: whiten($tagColor, 20);
  color: whiten($textColor, 12);
  background-color: whiten($tagColor, 96);


  &:hover,
  &:focus,
  &:active,
  &:global(.ant-tag-checkable-checked) {
    border-color: $tagColor;
    color: $textColor;
    background-color: whiten($tagColor, 92);
  }
}


.tangerine_toolbar {
  //display: flex;
  position: relative;
  //justify-content: space-around;
  color: rgb(133, 201, 186);

  .filter_tags {
    text-align: center;
    display: flex;
    gap: 1em;
    justify-content: space-around;

    :global(.ant-tag.ant-tag-checkable) {
      display: flex;
      align-items: center;
      //margin: 0 8px;
      font-size: 18px;
      padding: 2px 12px;
      border-style: dashed;

      @include tagStyle(rgba(92, 103, 103, 0.7), rgba(45, 44, 44, 0.7));

      &:global(.ant-tag-checkable-checked) {
        border-style: solid;
      }

      &:global(.ALL) {
        @include tagStyle($allColor);
      }

      &:global(.FOR_ME) {
        @include tagStyle($inboxColor);
      }

      &:global(.FROM_ME) {
        @include tagStyle($outboxColor);
      }

      &:global(.MY_DEPARTMENT) {
        @include tagStyle($deptColor);
      }

      &:global(.POPULAR) {
        @include tagStyle($likedColor);
      }

      :global(.total) {
        font-size: 16px;
        line-height: 22px;
      }

      :global(.anticon.anticon-loading) {
        font-size: 12px;
        color: gray;
      }
      //
      //&:hover {
      //  &:global(:not(.ant-tag-checkable-checked)) {
      //    color: rgb(1, 89, 107);
      //    border-color: $primaryGreen;
      //  }
      //}
    }
  }

  .radio_with_icon {
    padding: 2px 10px;
    height: 28px;
    line-height: 24px;

    :global(.anticon) {
      font-size: 16px;
      margin-right: 4px;
    }
  }

  .send_button:global(.ant-btn) {
    //position: absolute;
    //right: 0;
    //top: 0;
    //height: 30px;
    //width: 32px;
    line-height: 30px;
    //font-size: 20px;
    padding: 0 8px;
    z-index: 0;
    //margin-left: px;

    .btn_content {
      display: flex;
      align-items: center;
      gap: 6px;

      :global .anticon {
        font-size: 20px;
      }
    }


    :global .anticon {
      :global svg {
        //display: inline-block;
        //height: 30px;
        //font-size: 20px;
        //vertical-align: middle;
      }
    }


    &:not(:disabled) {
      background: $pinkRed;
      border: $pinkRed;


      &:hover,
      &:focus {
        background: whiten($pinkRed, 25);
        border: whiten($pinkRed, 25);

        &::after {
          animation-play-state: paused;
        }
      }

    }
  }
}

//
@keyframes slide {
  0% {
    transform: skewX(-20deg) translateX(-64px);
  }
  100% {
    transform: skewX(-20deg) translateX(16px);
  }
}
