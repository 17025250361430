@import 'src/colors';
//
$actionsWidth: 80px;

.archive-message-footer {
  justify-content: flex-end;
  display: flex;
  gap: 1em;

  .create-date {
    flex: 1 0 auto;
    font-size: 12px;
    line-height: 26px;
    color: rgba(50, 50, 50, 0.8);
  }

  .likes-container {
    display: flex;
    line-height: 24px;
    gap: 4px;
    padding-right: 8px;
    align-items: center;

    .heart-icon {
      .anticon.anticon-heart {
        color: $heartColor;
      }
    }

    .likes_count {
      font-size: 14px;
      margin-left: 4px;
      display: inline-block;
      width: 20px;
      text-align: start;
      color: rgb(46, 36, 36);

      &.empty {
        color: rgba(46, 38, 38, 0.95);
      }
    }

    .liked-number {
      font-size: 14px;
    }

    .ant-avatar.ant-avatar-sm {
      font-size: 13px;
      width: 22px;
      height: 22px;
      line-height: 19px;
    }

    &.me-liked {

    }
  }
}

.liked-people-popover {
  min-width: 400px;
  height: 150px;

  .ant-popover-inner {
    padding: 4px 6px;

    .liked_people_virtual_list {
      text-align: start;
    }

    .liked_person_item {
      text-align: start;
    }

    .ant-list {
      .ant-list-item {
        padding: 2px 4px;
        border-block-end: none;

        .ant-list-item-meta {
          align-items: center;

          .ant-list-item-meta-avatar {
            margin-inline-end: 6px;
          }

          .ant-list-item-meta-content {
            .ant-list-item-meta-title {
              margin-bottom: 0;
              min-width: 300px;
              font-size: 13px;
              line-height: 14px;
            }

          }
        }
      }
    }
  }
}

//
//.actions {

//
//  :global( > span, > button) {
//    &:not(:last-child) {
//      //margin-right: 10px;
//    }
//  }
//
//  :global(.ant-btn.ant-btn-sm.ant-btn-default) {
//    font-size: 16px;
//    padding: 0px 6px;
//    height: 26px;
//    line-height: 26px;
//    background: white;
//    color: rgba(10, 10, 10, 0.5);
//
//    .anticon.anticon-delete {
//      font-size: 14px;
//    }
//
//    .anticon.anticon-loading.anticon-spin {
//      vertical-align: 0;
//    }
//
//
//    .anticon {
//      &:global(.anticon-heart) {
//        color: $heartColor;
//      }
//    }
//
//    //:global(.ant-btn.ant-btn-default) {
//    //	&:not(:disabled) {
//    //		border-color: rgb(240, 240, 240);
//    //
//    //		&:hover {
//    //			border-color: whiten($primaryGreen, 80);
//    //		}
//    //	}
//    //}
//
//    border-color: rgb(240, 240, 240);
//
//    &:hover {
//      color: $primaryGreen;
//      border-color: whiten($primaryGreen, 50);
//      box-shadow: 0 0px 0 1px rgba(96, 150, 161, 0.2);
//
//      .anticon.anticon-heart {
//        color: $heartColorDark;
//      }
//    }
//
//    .like_container {
//      display: flex;
//      line-height: 24px;
//      gap: 0.5em;
//      align-items: center;
//

//    }
//
//  }
//
//  .like_btn:global(.ant-btn-default) {
//    height: 26px;
//
//    .like_btn_content {
//
//    }
//
//
//
//    &:hover {
//      &:global(.ant-btn-sm) {
//        border-color: whiten(rgb(241, 92, 73), 30);
//        box-shadow: 0 0 0 1px rgba(240, 113, 97, 0.2);
//
//        .liked_number {
//          color: rgb(29, 12, 12);
//        }
//
//      }
//    }
//  }
//}
//

//}
//
////
////@mixin steamAnimation($offsetY, $offsetX, $scale: 0.5) {
////	0% {
////		transform: translateY(0px) translateX(-16px) scale($scale);
////		opacity: 1;
////	}
////	100% {
////		transform: translateY($offsetY) translateX(-16px + $offsetX) scale(1);
////		opacity: 0;
////	}
////}
//
////.steam_container {
////	position: relative;
////
////	#steam1,
////	#steam2,
////	#steam3,
////	#steam4 {
////		position: absolute;
////		height: 16px;
////		width: 16px;
////		//border-radius: 50%;
////		z-index: 0;
////		opacity: 0;
////
////		:global( >  svg) {
////			:global( >  path) {}
////			fill: currentColor;
////		}
////	}
////
////	:global(#steam1) {
////		color: $purple;
////		//-webkit-animation: steam1 0.5s ease-out infinite;
////		//animation: steam1 0.5s ease-out infinite;
////	}
////
////	:global(#steam2) {
////		color: $pink;
////		//-webkit-animation: steam2 1s ease-out  infinite;
////		//animation: steam2 1s ease-out infinite;
////	}
////
////	:global(#steam3) {
////		color: rgb(23, 188, 66);
////		//-webkit-animation: steam3 0.2s ease-out infinite;
////		//animation: steam3 0.2s ease-out infinite;
////	}
////
////	:global(#steam4) {
////		color: $orangeRed;
////		//-webkit-animation: steam4 0.7s ease-out 0.2s infinite;
////		//animation: steam4 0.7s ease-out 0.2s infinite;
////	}
////
////	&.steaming {
////		width: 16px;
////		height: 16px;
////
////		:global(#steam1) {
////			//color: $purple;
////			-webkit-animation: steam1 1s ease-out;
////			animation: steam1 1s ease-out;
////		}
////
////		:global(#steam2) {
////			//color: $pink;
////			-webkit-animation: steam2 1.5s ease-out 0.3s;
////			animation: steam2 1.5s ease-out 0.3s;
////		}
////
////		:global(#steam3) {
////			//color: rgb(23, 188, 66);
////			-webkit-animation: steam3 0.5s ease-out 0.5s;
////			animation: steam3 0.5s ease-out 0.5s;
////		}
////
////		:global(#steam4) {
////			//color: $orangeRed;
////			-webkit-animation: steam4 1.2s ease-out 0.1s;
////			animation: steam4 1.2s ease-out 0.1s;
////		}
////	}
////}
////
////
////
////
////
////@-webkit-keyframes steam1 {
////	@include steamAnimation(-32px, -12px);
////}
////@keyframes steam1 {
////	@include steamAnimation(-32px, -12px);
////}
////
////
////@-webkit-keyframes steam2 {
////	@include steamAnimation(-28px, 16px);
////}
////
////@keyframes steam2 {
////	@include steamAnimation(-28px, 16px);
////}
////
////@-webkit-keyframes steam3 {
////	@include steamAnimation(-16px, -22px);
////}
////
////@keyframes steam3 {
////	@include steamAnimation(-16px, -22px);
////}
////
////@-webkit-keyframes steam4 {
////	@include steamAnimation(-48px, 8px);
////}
////
////@keyframes steam4 {
////	@include steamAnimation(-48px, 8px);
////}
////
