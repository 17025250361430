@import 'src/colors';

.user_select_container {
	padding-left: 12px;
	flex: 1 0 auto;
	max-width: calc(100% - 140px);

	&.invalid {
		:global .ant-select-selector {
			border-color: red;
			box-shadow: 0 0 0 2px rgba(255,0,0, 0.1);
		}

	}

	.max_recipients_hint {
		font-style: italic;
		color: gray;

		&.is_max {
			color: red;
		}
	}
}
