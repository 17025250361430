@import 'src/colors';

.tangerine_body {
	display: flex;
	width: 100%;
	align-items: center;
	gap: 4px;
	color: rgba(0, 0, 0, 0.95);
	position: relative;

	:global .anticon {
		color: rgba(125, 125, 125, 0.7);
		font-size: 15px;

	}

	.avatar {
		margin-right: 5px;
	}
	.person {
		flex: 1 0 auto;
	}

	.count {
		width: 120px;
		font-size: 15px;
		color: #504f4f;
	}
}
