@import 'src/colors.scss';

.profile {
	text-align: start;
	height: 100%;

	:global h4,
	:global h5 {
		font-weight: 500;
	}

	.color {
		font-size: 14px;
		margin-bottom: 30px;

		.color_selector {
			display: flex;
			gap: 1em;
			align-items: center;
			position: relative;


			.color_popover {
				position: absolute;
				z-index: 10;
				top: 13px;
				left: 150px;
				border-radius: 8px;
				box-shadow: 0 0 5px #7b7a7a;
			}
		}
	}

	.email_notifications {
		font-size: 14px;

		.switch_container {
			margin-bottom: 10px;

			:global .ant-switch {
				margin-right: 10px;

				&:global(.ant-switch-checked) {
					background-color: $green;
				}
			}
		}
	}


}
