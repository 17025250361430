.admin-view {
  text-align: start;

  .year-input {
    display: inline-block;
  }

  .ant-input {
    width: 150px;
    margin: 0 16px 0 6px;
  }
}
