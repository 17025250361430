@import 'src/colors.scss';

.infinite_scroll {
	.list {
		:global .ant-spin-nested-loading {
			//:global .ant-spin {
			//	top: 50%;
			//}
		}
	}

	.loader {
		text-align: center;
		padding: 6px 0;
	}

	.scroll_end_placeholder {
		text-align: center;
		font-size: 15px;
		padding: 6px 0;
		color: rgb(94 102 113);
		//display: flex;
		//align-items: center;
		//gap: 8px;
		//justify-content: center;
	}
}
