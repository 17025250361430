@import "colors.scss";

@font-face {
	font-family: "Brusnika";   /*Can be any text*/
	src: local("Brusnika"),
	url("./fonts/Brusnika.otf") format("truetype");
}

@font-face {
	font-family: "Blackberry";   /*Can be any text*/
	src: local("Blackberry"),
	url("./fonts/Blackberry.otf") format("truetype");
}

.App {
	height: 100vh;
	box-sizing: border-box;
	min-height: 300px;
	background-size: contain;
	padding: 10px;
	background-repeat: repeat;

	text-align: center;

	.app-container {
		display: inline-block;

		height: 100%;
		padding: 12px 16px;
		width: 800px;
		min-width: 800px;
		max-width: 900px;

		&.with_background {
			background: rgba(255, 254, 252, 0.98);
			box-shadow: 0 0 200px white;
		}

		.app-viewport {
			height: calc(100% - 54px);
		}
	}

}

.ant-btn.ant-btn-default {
	&:not(:disabled) {
		border-color: $borderColor;

		&:hover,
		&:focus {
			color: whiten($primaryGreen, 25);
			border-color: whiten($primaryGreen, 25);
		}
	}
}

.ant-btn.ant-btn-primary {
	@include buttonStyle($primaryGreen);

	&:not(:disabled) {
		color: white;

		&:hover,
		&:focus {
			color: white;
		}
	}
}

.ant-btn.ant-btn-link {
	&:not(:disabled) {
		color: $blue;

		&:hover {
			color: $blueLight;
		}
	}


}

.ant-radio-group {
	.ant-radio-button-wrapper {
		&.ant-radio-button-wrapper-checked {
			//background: $primaryGreen;
			border-color: $primaryGreen;
			color: $primaryGreen;

			&::before {
				//background-color: $primaryGreen;
			}
		}

		&:hover {
			&:not(.ant-radio-button-wrapper-checked):not(.ant-radio-button-wrapper-disabled) {
				color: $primaryGreen;
				//border-color: $primaryGreen;

				.ant-radio-button {
					&:not(.ant-radio-button-disabled) {
						color: $primaryGreen;
					}
				}
			}

		}

	}
}

.ant-checkbox-wrapper {
	@include checkboxStyle($primaryGreen);
}

.ant-pagination {
	&.ant-pagination-simple {
		.ant-pagination-total-text {
			vertical-align: top;
			line-height: 24px;
			height: 24px;
		}

		.ant-pagination-simple-pager {
			input {
				&:hover,
				&:focus,
				&:active {
					border-color: whiten($primaryGreen, 25);
					box-shadow: 0 0px 0 2px rgba($primaryGreen, 0.1)
				}
			}
		}

		.ant-pagination-item-link {
			&:hover {
				color: $primaryGreen;
			}
		}
	}
}

input {
	&:hover {
		border-color: whiten($primaryGreen, 25);
	}
}

.ant-tooltip {
	//color: black;
	//background-color: wheat;
	line-height: 16px;
	max-width: 350px;


	$tooltipColor: whitesmoke;

	.ant-tooltip-content {
		.ant-tooltip-arrow {
			color: $tooltipColor;

			&:before {
				background: $tooltipColor;
			}
		}

		.ant-tooltip-inner {
			color: black;
			font-size: 13px;
			background-color: $tooltipColor;
		}
	}
}

.ant-spin {
	.ant-spin-dot {
		color: $primaryGreen;

		.ant-spin-dot-item {
			background-color: $primaryGreen;
		}
	}
}

svg.otr_icon {
	fill: #F39629;
}

.ant-list.ant-list-sm {
	.ant-list-pagination {
		margin: 8px 0;
		//margin-block-start: 0;
	}
}

.ant-select {
	&:not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
		&:not(:disabled) {
			&:hover {
				.ant-select-selector {
					border-color: $primaryGreen;
				}
			}

			&:focus,
			&.ant-select-focused {
				.ant-select-selector {
					border-color: $primaryGreen;
					box-shadow: 0 0 0 2px rgb($primaryGreen, 10%);

				}
			}
		}
	}

}
