@import 'src/colors';

.feed {
	text-align: start;
	flex-flow: column;
	display: flex;
	//padding: 0 24px;
	height: 100%;

	.feed_title {
		text-align: center;
		background: linear-gradient(-45deg, $green, $primaryGreen, $orange, #e89a3e);
		background-size: 300%;
		font-weight: 600;
		font-size: 22px;
		text-transform: uppercase;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: animated_text 5s ease-in-out infinite;
		-moz-animation: animated_text 5s ease-in-out infinite;
		-webkit-animation: animated_text 5s ease-in-out infinite;
	}

	.filter {
		text-align: center;
		//margin-top: 16px;
		//margin-bottom: 6px;
	}

	.reload_btn_container {
		text-align: center;
		margin: 10px 0 5px 0;
		height: 32px;

		:global .anticon.anticon-loading {
			color: rgb(59, 128, 65);
		}

		.reload_btn:global(.ant-btn) {
			padding: 0;
			font-size: 15px;
			font-style: italic;
			color: rgb(59, 128, 65);
			border: none;

			&:not(:disabled) {
				&:hover {
					color: rgb(112, 174, 118);
				}
			}
		}
	}


	$filterHeight: 32px;
	$reloadBtnHeight: 47px;

	:global(.ant-list) {
		//max-height: calc(100% - ($filterHeight + $reloadBtnHeight));
		//border-color: rgba(237, 233, 230, 0.95);
		//min-height: 120px;
		////background: pink;
		//
		//:global(.ant-spin-nested-loading) {
		//	max-height: calc(100% - 40px);
		//	overflow-y: auto;
		//}
	}
}


@keyframes animated_text {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
