@import 'src/colors.scss';

//$iconGreen: rgb(110, 113, 106);
$iconGreen: rgb(73, 92, 118);
$avatarBackground: rgba(243, 249, 251, 0.74);

.app_header {
  display: flex;
  width: 100%;
  gap: 1em;
  align-items: center;

  .tangerine_title {

    display: inline-flex;
    align-items: center;
    gap: 4px;

      :global .anticon {
        font-size: 32px;
        //color: #fb9317;
      }

    .title {
      text-decoration: none;
      text-align: start;
      font-size: 34px;
      line-height: 34px;
      margin-top: 8px;
      font-weight: 600;
      font-family: "Brusnika", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      background: linear-gradient(-45deg, $pinkRed, $orange 80%, #fb9317);
      background-size: 100%;
      text-transform: uppercase;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      //animation: animated_text 8s ease-in-out infinite;
      //-moz-animation: animated_text 8s ease-in-out infinite;
      //-webkit-animation: animated_text 8s ease-in-out infinite;
    }
  }

  .user_name_container {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: flex-end;

    .tangerine_rank {
      margin-right: 16px;
      color: $pinkReal;
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 18px;

      &:global(:hover) {
        color: #ff195d;
        cursor: pointer;
      }
    }

    :global(.user-name-avatar) {
      display: flex;
      align-items: center;
      padding: 2px 6px;
      //border: 1px solid $avatarBackground;
      background: $avatarBackground;
      border-radius: 4px;
    }

    :global(.ant-dropdown-trigger.user-name-avatar) {
      :global(.user-name) {
        font-size: 14px;
      }

      .user_avatar {
        position: relative;
        width: 48px;

        &:hover {
          cursor: pointer;
        }

        .admin {
          position: absolute;
          width: 19px;
          height: 19px;
          left: 0;
          top: -6px;
          font-size: 15px;
          border-radius: 50%;
          line-height: 18px;
          background: rgb(203, 80, 54);
          z-index: 10;
          color: white;
          border: 1px solid white;
        }
      }

      &:hover {
        cursor: pointer;
        :global(.user-name) {
          color: rgb(3, 117, 92);
        }
      }
    }


    :global .ant-btn-link {
      margin-left: 5px;
      padding-left: 0;
      padding-right: 0;
      //color: rgb(62, 96, 143);
    }

  }
}
