@function whiten($color, $percentage) {
	@return mix(white, $color, $percentage);
}

@function darken($color, $percentage) {
	@return mix(#282828, $color, $percentage);
}

$backgroundPink: whiten(rgb(251, 205, 223), 90);
$backgroundBlue: whiten(rgb(107, 183, 207), 90);
$backgroundYellow: whiten(rgb(223, 213, 93), 90);

//$red: rgb(182, 41, 34);
//$pinkRed: rgba(248, 60, 75, 0.91);
//$pinkRedSoft: rgba(245, 91, 89, 0.8);
$orange: rgb(248, 133, 35);
$orangeRed: rgb(244, 74, 1);
$greenLight: rgb(161, 191, 127);
$green: rgb(112, 167, 48);
$greenBright: rgb(81, 148, 2);
$grassGreen: rgb(115, 192, 135);
$borderColor: rgb(192, 195, 195);


$pinkRed: rgba(225, 61, 17, 0.9);
$pinkRedSoft: rgba(245, 89, 48, 0.9);
//$primaryGreen: rgb(53, 140, 121);
$primaryGreen: rgb(125, 146, 61);
$heartColor: rgb(255, 105, 55);
$heartColorDark: rgb(242, 72, 15);
$purplePlum: rgb(148, 56, 140);
$pinkReal: #E63064;


$yellow: rgb(235, 166, 54);
$pink: rgb(241, 93, 62);
//$blue: rgb(88, 174, 95);
$brownedGray:rgba(71, 63, 63, 0.97);
$purple: rgb(31, 46, 121);
$blue: rgb(33, 69, 120);
$blueLight: rgb(56, 107, 178);

@mixin checkboxStyle($checkboxColor) {
	&:not(.ant-checkbox-wrapper-disabled) {
		&:hover {
			.ant-checkbox-inner {
				border-color: $checkboxColor;
			}
		}

	}

	.ant-checkbox {
		&:not(.ant-checkbox-disabled) {
			&:hover {
				border-color: $checkboxColor;
			}
		}
	}

	&.ant-checkbox-wrapper-checked {
		.ant-checkbox {
			&.ant-checkbox-checked {
				.ant-checkbox-inner {
					background-color: $checkboxColor;
					border-color: $checkboxColor;
				}

			}
		}

		&:hover {
			&:not(.ant-checkbox-disabled) {
				.ant-checkbox-inner {
					background-color: $checkboxColor;
					border-color: $checkboxColor;
				}
			}
		}
	}
}

@mixin buttonStyle($color) {
	&:not(:disabled) {
		background: $color;
		border-color: $color;

		&:hover,
		&:focus {
			background: whiten($color, 25);
			border-color: whiten($color, 25);
		}
	}
}


