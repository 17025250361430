@import 'src/colors';


.list_item:global(.ant-list-item) {
	position: relative;
	display: block;
	padding: 12px;
	padding-bottom: 4px;

	.list_item_content:global(.ant-list-item-meta) {
		:global(.ant-list-item-meta-avatar) {
			margin-right: 20px;
			margin-inline-end: 20px !important;

			.avatar_container {
				position: relative;
				text-align: center;
				width: 42px;

				.avatar_me {
					border-color: rgba(149, 120, 120, 0.8);
				}

				.create_date {
					text-align: center;
					font-style: italic;
					font-size: 13px;
					color: gray;
				}
			}

		}

		:global(.ant-list-item-meta-content) {
			text-align: start;

			$actionsWidth: 80px;

			:global(.ant-list-item-meta-description) {
				.tangerine_body {
					padding-top: 2px;
					position: relative;

					.message {
						color: rgba(0, 0, 0, 0.95);
						line-height: 18px;
						word-break: break-word;
					}


					.message_actions {
						background: transparent;
					}
				}

			}

		}
	}

	//
	&.sent_to_me {
		//background-color: rgba(227, 231, 255, 0.35);
		//background-color: rgba(236, 246, 245, 0.7);
		background-color: rgb(232, 247, 215, 0.4);
	}
}

