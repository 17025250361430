@import 'src/colors';


.text_area_with_emoji {
	position: relative;

	.text_area :global(textarea.ant-input) {
		border-color: rgb(119, 177, 164);
		box-shadow: 0 0 0 2px rgba(172, 212, 203, 0.3);
		padding-bottom: 24px;
		padding-right: 28px;

		::after {
			line-height: 24px;
		}
	}

	.emoji_btn:global(.ant-btn.ant-btn-default) {
		font-size: 16px;
		padding: 0;
		width: 18px;

		height: 18px;
		border: none;
		position: absolute;
		bottom: 26px;
		right: 14px;
		color: $orange;

		:global .anticon.anticon-smile {
			vertical-align: top;
		}

		&:hover {
			color: $orangeRed;
		}
	}

	.message_invalid {
		:global textarea.ant-input {
			border-color: rgba(249, 131, 131, 0.96);
			box-shadow: 0 0 0 2px rgb(230 78 78 / 10%);;
		}
	}

	:global textarea.ant-input {
		line-height: 18px;

	}

	.hint {
		color: gray;
		font-style: italic;

		&.hint_invalid {
			color: rgba(222, 65, 65, 0.93);
		}
	}
}
