.loadingMask {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(255, 251, 246, 0.27);
	align-items: center;
	justify-content: center;
	display: flex;

	:global(.ant-spin-show-text) {
		padding: 10px;
		border-radius: 4px;
		background: white;
	}

	:global(.spin) {
		//position: absolute;
		//top: 45%;
		//left: calc(50% - 10px);
	}

	&.withText {
		.spin {
			//left: calc(50% - 110px);
		}
	}
}
