@import 'src/colors';

@mixin tagStyle($tagColor, $textColor: $tagColor) {

	border-color: $tagColor;
	color: $textColor;
	background-color: whiten($tagColor, 92);
}

@mixin slideAnimation($time: 2s) {
	animation-play-state: running;
	content: '';
	top: 0;
	transform: translateX(100%);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	animation: slide $time infinite 0s;

	$firstColor: rgba(255, 255, 255, 0) 0%;
	$secondColor: rgba(254, 245, 245, 0.24) 50%;
	$thirdColor: rgba(128, 186, 232, 0) 99%;
	$finalColor: rgba(125, 185, 232, 0) 100%;

	background: -moz-linear-gradient(left, $firstColor, $secondColor, $thirdColor, $finalColor); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, $firstColor, $secondColor, $thirdColor, $finalColor); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, $firstColor, $secondColor, $thirdColor, $finalColor); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, $firstColor, $secondColor, $thirdColor, $finalColor); /* IE10+ */
	background: linear-gradient(to right, $firstColor, $secondColor, $thirdColor, $finalColor); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#B90022E8', GradientType=1); /* IE6-9 */
}

.tangerine_toolbar {
	//display: flex;
	position: relative;
	//justify-content: space-around;
	color: rgb(133, 201, 186);

	.filter_tags {
		text-align: center;
		display: flex;
		gap: 1em;
		justify-content: space-between;

		:global(.ant-tag.ant-tag-checkable) {
			margin: 0 8px;
			font-size: 16px;
			padding: 2px 12px;
			border-style: dashed;

			:global(.total) {
				font-size: 16px;
				line-height: 22px;
			}

			@include tagStyle(rgba(92, 103, 103, 0.7), rgba(45, 44, 44, 0.7));

			&:global(.ant-tag-checkable-checked) {
				border-style: solid;
				@include tagStyle($primaryGreen, rgb(56, 88, 81));
			}

			&:hover {
				&:global(:not(.ant-tag-checkable-checked)) {
					color: rgb(1, 89, 107);
					border-color: $primaryGreen;
				}
			}
		}
	}

	.radio_with_icon {
		padding: 2px 10px;
		height: 28px;
		line-height: 24px;

		:global(.anticon) {
			font-size: 16px;
			margin-right: 4px;
		}
	}

	.send_button:global(.ant-btn) {
		//position: absolute;
		//right: 0;
		//top: 0;
		//height: 30px;
		//width: 32px;
		line-height: 30px;
		//font-size: 20px;
		padding: 0 8px;
		z-index: 0;
		margin-left: 30px;

		.btn_content {
			display: flex;
			align-items: center;
			gap: 6px;

			:global .anticon{
				font-size: 20px;
			}
		}



		:global .anticon {
			:global svg {
				//display: inline-block;
				//height: 30px;
				//font-size: 20px;
				//vertical-align: middle;
			}
		}


		&:not(:disabled) {
			background: $pinkRed;
			border: $pinkRed;

			&::after {
				//@include slideAnimation(3s);
			}

			&:hover,
			&:focus {
				background: whiten($pinkRed, 25);
				border: whiten($pinkRed, 25);

				&::after {
					animation-play-state: paused;
				}
			}

		}
	}
}

//
@keyframes slide {
	0% {
		transform: skewX(-20deg) translateX(-64px);
	}
	100% {
		transform: skewX(-20deg) translateX(16px);
	}
}
