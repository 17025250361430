@import 'src/colors';


.place {
	font-size: 20px;
	color: rgb(70, 77, 73);
	//font-weight: 500;

	width: 60px;
	text-align: center;
	//position: absolute;
	.icon_first_place {
		&:global(.anticon) {
			font-size: 32px;
			color: #eaa803;
		}
	}


	.icon_second_place {
		&:global(.anticon) {
			font-size: 32px;
			color: #b7b7b7;
		}
	}

	.icon_third_place {
		&:global(.anticon) {
			font-size: 32px;
			color: #cd7f32;
		}
	}
}

