@import 'src/colors.scss';

.statistic_view {
	text-align: start;
	position: relative;
	height: 100%;

	:global h4,
	:global h5 {
		font-weight: 500;
	}

	.total {
		padding-left: 8px;

		.totals {
			display: flex;
			margin: 4px 0 16px 0;
			align-items: center;
			gap: 48px;

			.total_mandarines,
			.total_likes {
				display: flex;
				align-items: center;
				gap: 8px;

				.label {
					font-size: 16px;
					color: rgba(52, 52, 52, 0.8);
					padding-top: 3px
				}
			}

			.total_mandarines {
				:global .ant-statistic-content {
					color: $orange;
				}
			}

			.total_likes {
				:global .ant-statistic-content {
					color: $pinkRed;
				}

				:global .anticon.anticon-heart {
					font-size: 22px;
				}
			}

		}
	}

	.excel_btn {
		position: absolute;
		top: 74px;
		right: 0
	}

	.my_card {
		padding: 4px;
		box-shadow: 0 0 5px rgba(210, 210, 210, 0.85);
		margin-bottom: 10px;
	}

	:global .ant-list {
		//height: calc(100% - 94px - 84px);

		:global .ant-spin-nested-loading {
			//max-height: calc(100% - 40px);
			//overflow-y: auto;

			:global .ant-list-item {
				padding: 8px;

				&:global(.is_me) {
					//background-color: rgba(227, 231, 255, 0.35);
					background-color: rgba(183, 194, 246, 0.25);
				}

				:global .ant-statistic-content {
					font-size: 18px;

					.global .ant-statistic-content-value {

					}
				}


			}
		}
	}
}
